
;var agent = navigator.userAgent.toLowerCase(),
	isChromeDesktop = (agent.indexOf('chrome') > -1 && ((agent.indexOf('windows') > -1) || (agent.indexOf('macintosh') > -1) || (agent.indexOf('linux') > -1)) && agent.indexOf('mobile') < 0 && agent.indexOf('nexus') < 0),
	touch_capable = ('ontouchstart' in document.documentElement && ! isChromeDesktop);

// jQuery .naturalWidth and .naturalHeight plugin
// http://www.jacklmoore.com/notes/naturalwidth-and-naturalheight-in-ie/
document.addEventListener("DOMContentLoaded", function(event) {
(function($) {

	"use strict";

	var
		props = ['Width', 'Height'],
		prop;

	while (prop = props.pop( )) {
		(function (natural, prop) {
			$.fn[natural] = (natural in new Image( )) ?
				function ( ) {
					return this[0][natural];
				} :
				function ( ) {
					var
						node = this[0],
						img,
						value;

					if (node.tagName.toLowerCase( ) === 'img') {
						img = new Image( );
						img.src = node.src;
						value = img[prop];
					}
					return value;
				};
		}('natural' + prop, prop.toLowerCase( )));
	}

}(jQuery));
});

document.addEventListener("DOMContentLoaded", function(event) {

	const initZoomable = function() {
		// the image zoom functionality (for desktops)
		if (!$.hasOwnProperty("fancybox")) {
			// when a page re-includes jquery it breaks fancybox
			return;
		}
		$('.zoomable').fancybox({
			padding: 0,
			margin: 10,
			scrolling: 'no',
			mouseWheel: false,
			title: false,
			loop: false,
			tpl: {
				next: '<div class="fancybox-nav fancybox-next daz-fb"><a title="Next"><i class="fd fd-simple-cir-right"></i></a></div>',
				prev: '<div class="fancybox-nav fancybox-prev daz-fb"><a title="Prev"><i class="fd fd-simple-cir-left"></i></a></div>',
				closeBtn : '<a title="Close" class="fancybox-item fancybox-close daz-fb"><i class="fd fd-ex-cir"></i></a>'
			},
			beforeLoad: function( ) {
				daz.helper.stopBodyScroll( );
			},
			afterLoad: function(current, previous) {
				// destroy the zoom on the previous element
				if (previous) {
					$(previous.inner).trigger('zoom.destroy');
				}
			},
			beforeShow: async function() {
				this.mature = null;
				if (this.element[0].closest('.mature-container')?.classList.contains('obscure')) {
					this.mature = new DazMatureZoom(this.inner[0]);
					daz.mature.push(this.mature);
				}
			},
			afterShow: function( ) {
				// create the holder for the zoomed image
				// get the width and height of the window
				var win_width = $(window).width( ),
					win_height = $(window).height( ),
					$fb_wrap = $('div.fancybox-wrap'),
					$fb_inner = $('div.fancybox-inner'),
					$fb_outer = $('div.fancybox-overlay'),
					orig_overlay_overflow,
					orig_fb_css,
					orig_fb_inner_css,
					$inner = $(this.inner);
				timer = false;
				// if the margin on either side is more than 10 (set above), zoom is unnecessary
				if ( ! $fb_wrap.hasClass('fancybox-mobile') && (10 < Math.min(parseInt($fb_wrap.css('top').replace(/\D/g, ''), 10), parseInt($fb_wrap.css('left').replace(/\D/g, ''), 10)))) {
					return;
				}

				// show a zoom icon next to the close icon
				$('a.fancybox-close').after($('<a title="Expand" class="fancybox-zoom_in daz-fb"><i class="fd fd-mag-plus"></i></a>').on('click', function(evt) {
					evt.stopPropagation( );
					evt.preventDefault( );

					// because the zoom plugin doesn't expose the start function, fake a click on the image
					$inner.click( );
				}), $('<a title="Contract" class="fancybox-zoom_out daz-fb"><i class="fd fd-mag-minus"></i></a>').on('click', function(evt) {
					evt.stopPropagation( );
					evt.preventDefault( );

					// because the zoom plugin doesn't expose the start function, fake a click on the image
					$inner.trigger('stopeverything.zoom');
				}).hide( ));

				const doZoom = () => {
					$inner.zoom({
					on: 'toggle-grab',
					using: 'scroll',
					beforeZoomIn: function( ) {
						var margin = 0,
							this_width = (win_width - (margin * 2)),
							this_height = (win_height - (margin * 2)),
							orig_image_width = $(this).naturalWidth( ),
							orig_image_height = $(this).naturalHeight( ),
							wrap_width = $fb_wrap.outerWidth( ),
							wrap_height = $fb_wrap.outerHeight( ),
							wrap_left_margin = $fb_wrap.position( ).left,
							wrap_top_margin = $fb_wrap.position( ).top,
							used_width = Math.min(this_width, orig_image_width),
							used_height = Math.min(this_height, orig_image_height),
							left_margin = (touch_capable ? wrap_left_margin - Math.floor((used_width - wrap_width) / 2) : Math.floor((win_width - used_width) / 2)),
							top_margin = (touch_capable ? wrap_top_margin - Math.floor((used_height - wrap_height) / 2) : Math.floor((win_height - used_height) / 2));

						orig_fb_css = $fb_wrap.attr('style');
						orig_fb_inner_css = $fb_inner.attr('style');
						orig_overlay_overflow = $fb_outer.css('overflow');

						$fb_wrap.css({
							left: left_margin +'px',
							top: top_margin +'px',
							width: used_width +'px',
							height: used_height +'px'
						});

						$fb_inner.css({
							width: used_width +'px',
							height: used_height +'px',
							overflow: (touch_capable ? 'auto' : 'hidden')
						});

						$fb_outer.css('overflow', 'hidden');

						// switch the zoom in to a zoom out
						$('a.fancybox-zoom_in').hide( );
						$('a.fancybox-zoom_out').show( );
					},
					onZoomIn: function( ) {
						$('div.fancybox-inner').bind('touchmove.media', function(e) {
							e.stopPropagation( );
						});
					},
					onZoomOut: function( ) {
						$fb_wrap.attr('style', orig_fb_css);
						$fb_inner.attr('style', orig_fb_inner_css);
						$fb_outer.css('overflow', orig_overlay_overflow);
						$('div.fancybox-inner').unbind('touchmove.media');

						// switch the zoom out to a zoom in
						$('a.fancybox-zoom_out').hide( );
						$('a.fancybox-zoom_in').show( );
					}
				});
				}

				if (!this.mature) {
					doZoom();
				}
			},
			beforeClose: function( ) {
				// destroy the zoom on the element
				$(this.inner).trigger('zoom.destroy');
			},
			afterClose: function( ) {
				daz.helper.startBodyScroll( );
			}
		}).css('cursor', 'pointer');
	}
	initZoomable();
});

